import React, { Component } from "react";

import ChatContainer from "./Containers/ChatContainer";
import "./App.css";

class App extends Component {
  state = {};

  render() {
    return (
      <React.Fragment>
        <ChatContainer />
      </React.Fragment>
    );
  }
}

export default App;
