import React, { Component } from "react";
import io from "socket.io-client";
import Chat from "../Components/chat";

import SelectName from "../Components/selectName";

var serverURI = "http://larochatv3.react.laronir.com:80";
// var serverURI = "http://localhost:32140";

class ChatContainer extends Component {
  state = {
    socket: null,
    user: null,
    name: "",
    message: "",
    messages: [],
    status: "",
    isTyping: false,
    isTypingMessage: ""
  };

  componentWillMount() {
    const socket = io(serverURI);
    this.setState({ socket });
    this.initSocket(socket);
  }

  initSocket(socket) {
    socket.on("connect", value => {
      console.log("Connected");
    });
    socket.on("cleared", () => {
      this.setState({ messages: [] });
    });
    socket.on("status", data => this.getStatus(data));
    this.renderMessages(socket);
    this.renderIsTyping(socket);
  }
  renderMessages(socket) {
    socket.on("output", data => {
      if (data.length) {
        const messages = this.state.messages.slice();
        messages.push(data);
        this.setState({ messages });
      }
    });
  }

  renderIsTyping(socket) {
    socket.on("receiveTyping", data => {
      if (
        data.msgUserId !== this.state.socket.id &&
        this.state.isTypingMessage !== data
      ) {
        this.setState({ isTypingMessage: data });
      }
    });
  }

  getStatus = newStatus => {
    let statusDefault = this.state.status;
    if (
      newStatus !== statusDefault &&
      this.state.socket.id === newStatus.msgUserId
    ) {
      this.setState({ status: newStatus });
      setTimeout(() => {
        this.setState({ status: "" });
      }, 2000);
    }
  };

  handleInput = e => {
    const { socket, name, message } = this.state;
    if (e.which === 13 && e.shiftKey === false) {
      // Emit to server input
      socket.emit("input", {
        name: name,
        message: message,
        msgUserId: socket.id
      });
      e.preventDefault();

      this.setState({ message: "", isTyping: false });
    }
  };
  deleteMessages = () => {
    const { socket } = this.state;
    socket.emit("clear");
  };
  updateInputValue(event, param) {
    const { socket, name } = this.state;
    this.setState({
      [param]: event.target.value
    });
    if (param === "message" && event.target.value !== "") {
      socket.emit("isTyping", {
        name: name,
        msgUserId: socket.id
      });
    }
  }
  handleStatus = () => {
    const { status } = this.state;
    if (status) {
      let message = <div>{status.message}</div>;
      return message;
    } else {
      return "";
    }
  };

  setName = e => {
    const name = e.target.value;
    if (e.which === 13 && e.shiftKey === false) {
      this.setState({ name });
    }
  };

  render() {
    const { message, messages, isTypingMessage } = this.state;
    return (
      <React.Fragment>
        {!this.state.name ? (
          <SelectName setName={event => this.setName(event)} />
        ) : (
          <Chat
            messages={messages}
            updateInputValue={(event, param) =>
              this.updateInputValue(event, param)
            }
            handleInput={this.handleInput}
            deleteMessages={this.deleteMessages}
            message={message}
            key={"a"}
            handleStatus={this.handleStatus()}
            isTypingMessage={isTypingMessage}
          />
        )}
      </React.Fragment>
    );
  }
}

export default ChatContainer;
