import React, { Component } from "react";
import ScrollToBottom from "react-scroll-to-bottom";
import uniqid from "uniqid";

class Chat extends Component {
  state = {};

  displayStatus() {
    if (this.props.handleStatus !== "") {
      return <h1 className="statusMessage">{this.props.handleStatus}</h1>;
    }
    if (this.props.isTypingMessage !== "") {
      return (
        <h1 className="statusMessage">{this.props.isTypingMessage.message}</h1>
      );
    } else {
      return <h1> .</h1>;
    }
  }
  render() {
    const { messages, deleteMessages } = this.props;
    return (
      <div className="container">
        <div className="laro-flex-spacer" />
        <div className="row">
          <div className="col-md-6 offset-md-3 col-sm-12">
            <h1 className="text-center">
              <button
                onClick={deleteMessages}
                id="clear"
                className="btn btn-danger"
              >
                Clear
              </button>
            </h1>
            <div id="status" />
            <div id="chat">
              <br />
              <div className="card">
                <ScrollToBottom id="messages" className={"card-block messages"}>
                  {messages.map((res, index) => {
                    return (
                      <div key={uniqid()}>
                        {res.map(message => {
                          return (
                            <div className={"single-message"} key={uniqid()}>
                              {" "}
                              <strong>{message.name}: </strong>{" "}
                              <span>{message.message}</span>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </ScrollToBottom>
              </div>

              <br />
              <div
                style={{ maxWidth: "100%", marginBottom: 5 }}
                className="webflow-style-input"
              >
                <input
                  id="textarea"
                  placeholder="Enter message..."
                  onChange={e => this.props.updateInputValue(e, "message")}
                  onKeyDown={this.props.handleInput}
                  value={this.props.message}
                />
              </div>
            </div>
            {this.displayStatus()}
          </div>
        </div>

        <div className="laro-flex-spacer" />
      </div>
    );
  }
}

export default Chat;
