import React, { Component } from "react";
import "./selectName.scss";

class SelectName extends Component {
  state = {};
  render() {
    return (
      <div className="container">
        {" "}
        <div className="laro-flex-spacer" />
        <div className="webflow-style-input">
          <input
            type="text"
            id="username"
            placeholder="Enter your name..."
            value={this.state.value}
            onKeyDown={e => this.props.setName(e)}
          />
        </div>
        <div className="laro-flex-spacer" />
      </div>
    );
  }
}

export default SelectName;
